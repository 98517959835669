import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 600px) {
    .desktopView {
      display: none;
    }

    .mobileSlider {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      margin: 5% auto;
      margin-top: 44px;
    }
  }

  @media screen and (min-width: 601px) {
    .mobileView {
      display: none;
    }

    .desktopSlider {
      position: fixed;
      width: 92%;
      max-width: 1000px;
      left: 0;
      right: 0;
      top: 0;
      margin: 5% auto;
      margin-top: 50px;
    }
  }
`;
