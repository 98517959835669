import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Dr. Michael Hawke`}</MDXTag>{` is a Professor Emeritus, Department of Otolaryngology-Head and Neck Surgery at the University of Toronto. He is one of the pioneers in medical photography and has a true passion for it, having amassed over 10,000 photographs of the ear in his personal library. These photographs were captured using telescopic techniques that he also helped pioneer. We were honoured and fortunate to have Dr. Hawke act as a consultant in the development of OtoSim™ and grateful to him for granting OtoSim Inc exclusivity to his collection of medical photographs.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  