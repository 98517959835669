import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Dr. Vito Forte`}</MDXTag>{` is the CEO and Chairman of the Board of OtoSim Inc, and a co-inventor of OtoSim™. Dr. Forte retired from clinical practice in 2015 as a Paediatric Otolaryngologist and former Otolaryngologist-in-Chief and Associate Surgeon-in-Chief at The Hospital for Sick Children (SickKids). He remains a full Professor in the Department of Otolaryngology-Head and Neck Surgery, Faculty of Medicine at the University of Toronto. His acquired skill set, lifelong passion for teaching, engineering and innovation are now being deployed as a Liaison Officer in the Department of Surgery to create a better translation link between clinicians and engineers. In 2013, he was one of the recipients of the 2013 Canadian Association of Medical Educators (CAME) Certificate of Merit Award. In 2015, he was the recipient of the The Ernest C. Manning Foundation Innovation Award. This is awarded annually to talented Canadian innovators who are improving the lives of Canadians and others worldwide through their commercialized innovations. This award recognizes innovators for the value they are adding to our provincial and national economies by creating jobs and wealth, and positioning our country as a global competitor.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  