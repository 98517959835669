import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Drs. Vito Forte`}</MDXTag>{` and `}<MDXTag name="strong" components={components} parentName="p">{`Paolo Campisi`}</MDXTag>{` recognized the deficiencies that exist in current-day otoscopic training. The OtoSim™ device was pioneered to resolve the problem and proved its value through a scientific study. A medical teaching tool that ensures verifiable learning was born with OtoSim™.`}</MDXTag>
<MDXTag name="p" components={components}>{`Based on customer feedback and demand, we continued to refine these initial products releasing `}<MDXTag name="strong" components={components} parentName="p">{`OtoSim2™`}</MDXTag>{` and adding `}<MDXTag name="strong" components={components} parentName="p">{`PneumatoSim™`}</MDXTag>{`.  We also developed the `}<MDXTag name="strong" components={components} parentName="p">{`Educators Toolkit™`}</MDXTag>{` to allow teachers to create their own curriculum, using their own images and material. All were developed in conjunction with key educators and clinicians from the `}<MDXTag name="strong" components={components} parentName="p">{`University of Toronto`}</MDXTag>{` and the `}<MDXTag name="strong" components={components} parentName="p">{`Hospital for Sick Children`}</MDXTag>{` (SickKids).`}</MDXTag>
<MDXTag name="p" components={components}>{`Recognizing the complexities and fiscal realities involved in institutional curriculum development we developed our cloud and web-based products `}<MDXTag name="strong" components={components} parentName="p">{`OtoSim Mobile™`}</MDXTag>{` and `}<MDXTag name="strong" components={components} parentName="p">{`OtoSim Educators Portal™`}</MDXTag>{`.  These innovative interactive products convert any smartphone or tablet into a portable simulator allowing the student to learn and an instructor to teach otoscopy from Anywhere; at Anytime; and at Any Pace and make the educational experience enjoyable, efficient and economical.  `}</MDXTag>
<MDXTag name="p" components={components}>{`Our medical training technologies have been adopted in Canada, USA and globally, and benefited these healthcare disciplines:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Medicine`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Nurse Practitioners`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Physician Assistants`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Optometry`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Osteopathic Medicine`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Naturopathic Medicine`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Audiologists`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  