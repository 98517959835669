import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Dr. Paolo Campisi`}</MDXTag>{` is a co-inventor of OtoSim™. He is a paediatric otolaryngologist in the Department of Otolaryngology – Head and Neck Surgery at the Hospital for Sick Children. He is Professor and Vice Chair of Education, and the Director of Postgraduate Education (formerly Director of Undergraduate Education) in the Department of Otolaryngology – Head & Neck Surgery at the University of Toronto. Dr. Campisi led the validation of teaching protocols for medical students in developing OtoSim™, confirming the benefits of OtoSim™ by demonstrating significant improvement in students’ diagnostic accuracy.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  