import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Box, Stack, Heading, Text } from 'grommet';
import Slider from 'react-slick';
import { Container } from './slider.css';
import RouteButton from 'components/routeButton';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ClientCard = ({ image }) => (
  <div>
    <Box>
      <Box width="150px" height="150px" margin="small" alignSelf="center">
        <Img
          fluid={image ? image.childImageSharp.fluid : {}}
          alt="Client Logo"
        />
      </Box>
    </Box>
  </div>
);

ClientCard.propTypes = {
  image: PropTypes.object.isRequired,
};

class ClientSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 500,
      draggable: false,
      accessibility: false,
      arrows: false,
      centerMode: false,
      pauseOnHover: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      rows: 2,
    };
    return (
      <Box width="xlarge" alignSelf="center">
        <div>
          <Slider {...settings}>
            {this.props.clients.map((client, i) => (
              <ClientCard {...client} key={i} />
            ))}
          </Slider>
        </div>
      </Box>
    );
  }
}

const CoverCard = ({ image, text, subtext, link, background, display }) => (
  <div style={{ height: '650px' }}>
    <Stack anchor="left">
      <Img fluid={image ? image.childImageSharp.fluid : {}} alt="Cover Image" />
      <Box style={{ display: display }}>
        <Box
          margin={{ left: 'xlarge', top: 'xlarge' }}
          pad={{ left: 'small', right: 'small' }}
          round
          background={{
            color: background ? background : 'none',
            opacity: 0.4,
          }}
        >
          <Heading size="medium" color="light-1" margin="xsmall">
            {text}
          </Heading>
          <Text weight="bold" color="light-1" margin={{ left: 'xsmall' }}>
            {subtext}
          </Text>
          <RouteButton to={link} label="Learn More" alignPosition="start" />
        </Box>
      </Box>
    </Stack>
  </div>
);

CoverCard.propTypes = {
  image: PropTypes.object.isRequired,
  display: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
};

class CoverSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 500,
      draggable: false,
      accessibility: true,
      nextArrow: (
        <IoMdArrowForward color="black" style={{ fontSize: '20px' }} />
      ),
      prevArrow: <IoMdArrowBack color="black" />,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Container>
        <div className="desktopView">
          <div className="desktopSlider">
            <Slider {...settings}>
              {this.props.images.map((imageData, i) => (
                <CoverCard {...imageData} key={i} />
              ))}
            </Slider>
          </div>
          <Box height="670px" />
        </div>
        <div className="mobileView">
          <div className="mobileSlider">
            <Slider {...settings}>
              {this.props.images.map((image, i) => (
                <CoverCard {...image} display="none" key={i} />
              ))}
            </Slider>
          </div>
          <Box height="235px" />
        </div>
      </Container>
    );
  }
}

export { CoverSlider, ClientSlider };
