import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`OtoSim Inc.`}</MDXTag>{` is a Toronto-based medical simulation company focused on otoscopy and ophthalmoscopy training. Our goal is to enhance patient outcomes through better training of primary healthcare providers, with effective and innovative simulation tools.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  