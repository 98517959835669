import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`Next up was addressing the problem in ophthalmoscopy training and increasing diagnostic accuracy. `}<MDXTag name="strong" components={components} parentName="p">{`Dr. Kenneth Eng`}</MDXTag>{`, Director of Undergraduate Medical Education for the Department of Ophthalmology and Vision Sciences at the University of Toronto, provided the ophthalmology perspective to develop OphthoSim™.`}</MDXTag>
<MDXTag name="p" components={components}>{`A medical teaching tool that ensures verifiable learning was born with OphthoSim™.`}</MDXTag>
<MDXTag name="p" components={components}>{`As with otoscopy we developed our cloud and web-based products for ophthalmoscopy, the `}<MDXTag name="strong" components={components} parentName="p">{`OphthoSim Mobile™`}</MDXTag>{` and `}<MDXTag name="strong" components={components} parentName="p">{`OphthoSim Educators Portal™`}</MDXTag>{` allowing the student to learn and an instructor to teach opththalmoscopy similarily from Anywhere; at Anytime; and at Any Pace.`}</MDXTag>
<MDXTag name="p" components={components}>{`Our medical training systems have been adopted in Canada, USA, and globally by medical schools, nursing schools, physician assistant programs, allied health programs, as well as teaching hospitals and clinics.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  