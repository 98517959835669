import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box } from 'grommet';
import Img from 'gatsby-image';
import SectionTitle from 'components/title/sectionTitle';
import ProductIntro from 'components/productIntro';
import { Card } from 'components/pageCards';
import { ClientSlider } from 'components/slider';

import Intro from '../../content/about/intro.mdx';
import Section1 from '../../content/about/section1.mdx';
import Section2 from '../../content/about/section2.mdx';
import Vito from '../../content/about/vito.mdx';
import Paolo from '../../content/about/paolo.mdx';
import Michael from '../../content/about/michael.mdx';

const Inventor = ({ image, children }) => (
  <Box direction="row-responsive" margin="small">
    <Box width="small" alignSelf="center" margin="small">
      <Img
        fluid={image ? image.childImageSharp.fluid : {}}
        alt="Inventor Picture"
      />
    </Box>

    <Box width="75%" alignSelf="center" margin="medium">
      {children}
    </Box>
  </Box>
);

Inventor.propTypes = {
  image: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const SupporterCard = ({ image }) => (
  <Box width="150px" height="150px" margin="small">
    <Img fluid={image ? image.childImageSharp.fluid : {}} alt="Client Logo" />
  </Box>
);

SupporterCard.propTypes = {
  image: PropTypes.object.isRequired,
};

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <Box fill="horizontal">
      <Box width="xlarge" alignSelf="center">
        <SectionTitle title="About OtoSim" />
        <ProductIntro>
          <Intro />
        </ProductIntro>
        <Card image={data.aboutJson.otosim2} alignImage="right">
          <Section1 />
        </Card>
        <Card image={data.aboutJson.ophthosim} alignImage="left">
          <Section2 />
        </Card>
        <Box background="light-1" alignSelf="center">
          <Inventor image={data.aboutJson.vitoPic}>
            <Vito />
          </Inventor>
          <Inventor image={data.aboutJson.paoloPic}>
            <Paolo />
          </Inventor>
          <Inventor image={data.aboutJson.michaelPic}>
            <Michael />
          </Inventor>
        </Box>
        <SectionTitle title="Supporters" />
        <ClientSlider clients={data.aboutJson.supporters} />
      </Box>
    </Box>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      otosim2 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ophthosim {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      supporters {
        image {
          childImageSharp {
            fluid(maxWidth: 200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      vitoPic {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      paoloPic {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      michaelPic {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
